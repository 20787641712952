import { Helmet } from 'react-helmet';
import { Link, navigate } from '@reach/router';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import * as styles from './view.module.css';
import { scaler } from '../../utils';
import Opal from '../../templates/Opal';
import Moonstone from '../../templates/Moonstone';
import DatabaseContext from '../../contexts/DatabaseContext';
import Emerald from '../../templates/Emerald';
import Pearl from '../../templates/Pearl';
import LoadingScreen from '../../components/router/LoadingScreen';
import Jade from '../../templates/Jade';
import Diamond from '../../templates/Diamond';
import Ruby from '../../templates/Ruby';
import fontSizeOptions from '../../data/fontSizeOptions';

const ResumeViewer = ({ id }) => {
  const { t, i18n } = useTranslation();
  const [resume, setResume] = useState(null);
  const [loading, setLoading] = useState(true);
  const { getResume } = useContext(DatabaseContext);

  useEffect(() => {
    (async () => {
      const data = await getResume(id);

      if (!data) {
        navigate('/');
        toast.error(
          `The resume you were looking for does not exist anymore... or maybe it never did?`,
        );
        return null;
      }

      setResume(data);
      i18n.changeLanguage(data.metadata.language || 'en');

      for (const [key, sizeDefault] of Object.entries(fontSizeOptions)) {
        document.documentElement.style.setProperty(
          key,
          `${scaler(data.metadata.fontSize) * sizeDefault}rem`,
        );
      }

      return setLoading(false);
    })();
  }, [id]);

  return useMemo(() => {
    if (loading) {
      return <LoadingScreen />;
    }

    return (
      <div className={styles.container}>
        <Helmet>
          <title>
            {resume.name} | {t('shared.appName')}
          </title>
          <link rel="canonical" href={`https://rxresu.me/r/${id}`} />
        </Helmet>

        <div
          className={styles.page}
          style={{ backgroundColor: resume.metadata.colors.background }}
        >
          {resume.metadata.template === 'jade' && <Jade data={resume} />}
          {resume.metadata.template === 'diamond' && <Diamond data={resume} />}
          {resume.metadata.template === 'ruby' && <Ruby data={resume} />}
          {resume.metadata.template === 'emerald' && <Emerald data={resume} />}
          {resume.metadata.template === 'opal' && <Opal data={resume} />}
          {resume.metadata.template === 'pearl' && <Pearl data={resume} />}
          {resume.metadata.template === 'moonstone' && (
            <Moonstone data={resume} />
          )}
        </div>

        <p className={styles.footer}>
          Built with <Link to="/">Reactive Resume</Link>
        </p>
      </div>
    );
  });
};

export default memo(ResumeViewer);
